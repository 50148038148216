<template>
    <div class="right-side">
        <h2>{{$t('navigation.trule')}}</h2>

        <beat-loader 
            v-if="loading" 
            class="loader-center" 
            :color="'#553DB4'" 
            :loading="loading" 
            :size="20" 
            :sizeUnit="'px'"
        /> 

        <div v-else class="doc-table">
            <div v-for="c in categories" :key="c">
                <h3 class="mt-5"> {{ $t('InformDocs.' + c) }} </h3>
            <div 
                class="doc-table__row"
                v-for="doc in getDocuments(c)"
                :key="doc.id"
            >
                <div class="doc__item">
                    <img src="@/assets/img/icons/inform.svg" alt="">
                    <p> {{doc.nameRu}} </p>
                </div>
                <div class="doc__item">
                    <p> {{doc.created}} </p>
                    <a :href="`https://eoz.kz/${doc.url}`"><i class="fas fa-download"> </i></a>
                </div>
            </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "DocumentPage",
        data: () => ({
            docs: [],
            categories: [
                'gos',
                'kvazi',
                'samruk',
                'nadloc',
                'kazavtozhol',
                'baiterek',
                'kazgeologia'
            ],
            loading: true,
        }),
        mounted() {
            this.getPlans()
        },
        methods: {
            getPlans() {
                // this.loading = true;
                let params = {
                    page: 0,
                    entity: 'Doclink',
                    length: 15,
                }

                 this.$store.dispatch('GET_LIST', params) 
                    .then((res) => {
                        this.loading = true
                        this.docs = res.data.content
                        this.loading = false
                    })
            },
            getDocuments(category) {
                return this.docs.filter( i => i.category == category).sort((a, b) => a.order - b.order);
            }
        }
    }
</script>

<style lang="scss" scoped>

</style>